import LoginSettings, {
  AppInsightsConfig
} from '@Shared.Angular/@types/loginSettings';
import angular from 'angular';

class APP_CONFIG {
  public ready: Promise<void>;
  private setReady: () => void;

  constructor() {
    this.ready = new Promise((resolve) => (this.setReady = resolve));
  }

  auth0ClientId: string;
  enableWebhooks: boolean;
  recaptchaSiteKey: string;
  sessionTimeoutInSecond = 14400;
  supportHours = '';
  outOfHoursSupportMessage = '';
  apiBaseUrl: string;
  flowModelSizeLimit: number;
  enableStepTasks: boolean;
  allowStepRules: boolean;
  flowinglyEnvironment: string; // Are we in dev, staging, or prod which use unique namespaces for local storage
  brandLogoClass: string;
  hideSupportButton: boolean;
  enableSubCategories: boolean;
  enableTenantSwitching: boolean;
  enableTemplateLibrary: boolean;
  enableFlowModelCompare: boolean;
  brandingName: string;
  brandingModelerHelpUri: string;
  brandingHelpUri: string;
  brandingHelpTitle: string;
  keyForAuthLoggingId: string;
  disableEveryoneMentions: boolean;
  defaultDays: number;
  enableConsoleLogging: boolean;
  cfEnableConditionalRules: boolean;
  cfDecisionFieldTypesAllowed: string;
  cfActionFieldTypesDisallowed: string;
  auth0Domain = '';
  brandingEmailLogo = '';
  brandingSendingEmailAddress = '';
  disableXssValidation = false;
  showConfidentialFlowOption = false;
  enableCustomPublicFormSubjects = false;
  enableModelerXmlExport = false;
  enableTableFormula = false;
  flowModelFeedbackVisibility = 'flowowners';
  fmoCanPublish = false;
  modelerUrl = '';
  runnerUrl = window.origin;
  showLogoFields = false;
  signalrServerUrl = '';
  stepTimeCostVisibility = 'Both';
  showVisibilityToggleInsideConfiguration = false;
  enableConditionalForms = false;
  iPaaSSupportedStepTypes = '';
  enableIPaaSIntegration = false;
  welcomeText = 'Welcome';
  enableDatabaseFeature = false;
  allowProcessMap = false;
  enableProcessMapsV2 = false;
  maxSubCategories = 0;
  enableAuthenticationLogging = false;
  enableAppInsightsRunner = false;
  googleTagManagerId = '';
  runnerClosedLogoUrl = '';
  runnerOpenLogoUrl = '';
  runnerTitle = '';
  showReportDatesInUtc = false;
  auth0UseUniversalLogin = false;
  intercomId = '';
  intercomUrl = '';
  isDatabaseFeatureEnabled = false;
  localDateTimeFormat = 'DD/MM/YYYY h:mm:ss a';
  maximumWebhooksOnStep = 5;
  maxReportReturnRows = 1000;
  favIcon: string;
  flowFilterMaxCharacters;
  gridReportPageSize = 25;
  hideInviteUsers = false;
  loginAlertMessage = '';
  enableTemplateManagement: boolean;
  allowProcessMapsAsComponents: boolean;
  brandingHomepageUri = '';
  brandingSupportEmail = '';
  disableAdminCategoryEdit = false;
  disableAdminDatabaseDelete = false;
  disableAdminTeamEdit = false;
  disableAdminUserEdit = false;
  disableFlowModelCreation: boolean;
  defaultHomePage = 'flowsactive';
  enableExago = false;
  enableReportOwner = false;
  logoUrl: string;
  exagoBaseUrl: string;
  sparkPostCustomEmailFrom: string;
  maxNumberOfDaysForRecentlyUpdatedMaps = 0;
  showDashboardsV1: boolean;
  enablePublicProcessMaps = true;
  workflowParserVersion: number;
  displayUsersDropdownInSendTestEmail = false;
  enableConfidentialStep: boolean;
  userWebPushNotificationsEnabled: boolean;
  enableWebPushNotificationsMobile: boolean;
  enableWebPushNotificationsNonMobile: boolean;
  webPushPublicKey: string;
  enableFlowDelete: boolean = undefined;
  auth0UseRefreshToken = false;
  auth0AppV2ClientId: string;
  flowsInDateFilterDefaultRange: number;
  flowsInDateFilterMaxRange: number;
  enableModelerFlowModelTypeSelection: boolean;
  enableFormFieldReCaptcha: boolean = undefined;
  enableConnectorPoints: boolean;

  appInsightsRunnerConfig: AppInsightsConfig | undefined;

  runnerRehomeUrl = '';

  exagoConfig = {
    defaultDashboard: {
      folder: 'Dashboards',
      name: 'My Consumption Dashboard'
    }
  };

  intercomConfig = {
    id: '',
    pollDelay: 5000
  };

  // settings from settings.json that are not overridden with login or business settings
  noOverwriteSettings: string[] = [];
  isSettingOverwritable(settingName: string): boolean {
    const exist = this.noOverwriteSettings.includes(settingName);
    return !exist;
  }

  isTrueString(value: unknown) {
    return typeof value === 'string' && value.toLowerCase().trim() === 'true';
  }

  settings;
  public updateSetting(
    settingName: string,
    settingValue: string,
    isBoolean = false,
    propertyName = null
  ) {
    if (propertyName === null) {
      propertyName = settingName.replace(/[^A-Za-z0-9]*/g, '');
      propertyName =
        propertyName.charAt(0).toLowerCase() + propertyName.slice(1);
    }

    if (
      typeof settingValue !== 'string' ||
      !this.isSettingOverwritable(propertyName)
    ) {
      return;
    }

    this[propertyName] = isBoolean
      ? this.isTrueString(settingValue)
      : settingValue;
  }

  private tryApplySetting(
    settingName: string,
    isBoolean = false,
    propertyName = null
  ) {
    const value = this.settings[settingName];
    this.updateSetting(settingName, value, isBoolean, propertyName);
  }

  /**
   * Pass data from window.settings to local fields
   * @param settings
   */
  public populateFromSettingsJSON(settings: object) {
    // clear noOverwriteSettings
    this.noOverwriteSettings = [];

    for (const [key, value] of Object.entries(settings)) {
      this[key] = value;
      this.noOverwriteSettings.push(key);
    }
  }

  /**
   * Populate data from /settings/login?domainname api
   * @param loginSettings
   */
  public populateLoginSettings(loginSettings: LoginSettings) {
    const {
      welcomeText,
      loginAlertMessage,
      auth0UseUniversalLogin,
      logoUrl,
      title,
      favIcon,
      sessionTimeoutInSecond,
      auth0UseRefreshToken: auth0UseRefreshToken,
      logging: {
        enableAuthenticationLogging,
        enableAppInsightsRunner,
        appInsightsRunnerConfig
      }
    } = loginSettings;
    if (this.isSettingOverwritable('welcomeText')) {
      this.welcomeText = welcomeText;
    }

    if (this.isSettingOverwritable('loginAlertMessage')) {
      this.loginAlertMessage = loginAlertMessage;
    }

    if (this.isSettingOverwritable('auth0UseUniversalLogin')) {
      this.auth0UseUniversalLogin = auth0UseUniversalLogin;
    }

    if (this.isSettingOverwritable('auth0UseRefreshToken')) {
      this.auth0UseRefreshToken = auth0UseRefreshToken;
    }

    if (this.isSettingOverwritable('logoUrl')) {
      this.logoUrl = logoUrl;
    }

    if (this.isSettingOverwritable('enableAppInsightsRunner')) {
      this.enableAppInsightsRunner = enableAppInsightsRunner;
    }

    if (this.isSettingOverwritable('enableAuthenticationLogging')) {
      this.enableAuthenticationLogging = enableAuthenticationLogging;
    }

    if (this.isSettingOverwritable('appInsightsRunnerConfig')) {
      this.appInsightsRunnerConfig = appInsightsRunnerConfig;
    }

    if (this.isSettingOverwritable('runnerTitle')) {
      this.runnerTitle = title;
    }

    if (this.isSettingOverwritable('favIcon')) {
      this.favIcon = favIcon;
    }

    if (this.isSettingOverwritable('sessionTimeoutInSecond')) {
      this.sessionTimeoutInSecond = sessionTimeoutInSecond;
    }

    if (this.isSettingOverwritable('auth0UseRefreshToken')) {
      this.auth0UseRefreshToken = auth0UseRefreshToken;
    }
  }

  /**
   * Populate data from account/settings api
   * @param settings
   */
  public populateSettings(settings) {
    this.settings = settings;

    // Ideally we would provide the setting type so we could iterate over settings
    // instead of having to define each setting like this.

    // In the meantime we should use tryApplySetting to keep things simple
    this.tryApplySetting('EnableSetupRoles', true);
    this.tryApplySetting('EnableTemplateLibrary', true);
    this.tryApplySetting('EnableTemplateManagement', true);
    this.tryApplySetting('DisableFlowModelCreation', true);
    this.tryApplySetting('DisableEveryoneMentions', true);
    this.tryApplySetting('Branding:HomepageUri');
    this.tryApplySetting('Branding:SupportEmail');
    this.tryApplySetting('BrandLogoClass');
    this.tryApplySetting('AllowProcessMapsAsComponents', true);

    this.tryApplySetting('AllowStepRules', true);
    this.tryApplySetting('Branding:Name');
    this.tryApplySetting('Branding:HelpTitle');
    this.tryApplySetting('Branding:ModelerHelpUri');
    this.tryApplySetting('Branding:Email:Logo');
    this.tryApplySetting(
      'Branding:Email:SendingEmailAddress',
      false,
      'brandingSendingEmailAddress'
    );
    this.tryApplySetting(
      'SparkPost.CustomEmailFrom',
      false,
      'sparkPostCustomEmailFrom'
    );
    this.tryApplySetting('DisableXssValidation', true);
    this.tryApplySetting('EnableConditionalForms', true);
    this.tryApplySetting('EnableConsoleLogging', true);
    this.tryApplySetting('EnableCustomPublicFormSubjects', true);
    this.tryApplySetting('EnableModelerXmlExport', true);
    this.tryApplySetting('EnableStepTasks', true);
    this.tryApplySetting('EnableTableFormula', true);
    this.tryApplySetting('EnableWebhooks', true);
    this.tryApplySetting('HideSupportButton', true);
    this.tryApplySetting('OutOfHoursSupportMessage');
    this.tryApplySetting('ShowConfidentialFlowOption', true);
    this.tryApplySetting('ShowLogoFields', true);
    this.tryApplySetting('ShowVisibilityToggleInsideConfiguration', true);
    this.tryApplySetting('SupportHours');
    this.tryApplySetting('auth0:ClientId');
    this.tryApplySetting('auth0:Domain');
    this.tryApplySetting('UserWebPushNotificationsEnabled', true);
    this.tryApplySetting('EnableWebPushNotificationsMobile', true);
    this.tryApplySetting('EnableWebPushNotificationsNonMobile', true);
    this.tryApplySetting('WebPushPublicKey');

    this.tryApplySetting('FMOCanPublish', true, 'fmoCanPublish');
    this.tryApplySetting(
      'CF:DecisionFieldTypesAllowed',
      false,
      'cfDecisionFieldTypesAllowed'
    );
    this.tryApplySetting(
      'CF:EnableConditionalRules',
      true,
      'cfEnableConditionalRules'
    );
    this.tryApplySetting(
      'CF:ActionFieldTypesDisallowed',
      false,
      'cfActionFieldTypesDisallowed'
    );
    this.tryApplySetting('EnableSubCategories', true);
    this.tryApplySetting('ShowDashboardsV1', true);
    this.tryApplySetting('DisplayUsersDropdownInSendTestEmail', true);
    this.tryApplySetting('EnableConfidentialStep', true);
    this.tryApplySetting('EnableFlowModelCompare', true);
    this.tryApplySetting('EnableConnectorPoints', true);

    if (
      settings['WorkflowParserVersion'] &&
      this.isSettingOverwritable('workflowParserVersion')
    ) {
      this.workflowParserVersion = +settings['WorkflowParserVersion'];
    }
    if (
      settings['Flowingly.Core.ServiceCatalog.Api'] &&
      this.isSettingOverwritable('apiBaseUrl')
    ) {
      this.apiBaseUrl = settings['Flowingly.Core.ServiceCatalog.Api'] + '/api/';
    }

    if (
      settings['FlowModelFeedbackVisibility'] &&
      this.isSettingOverwritable('flowModelFeedbackVisibility')
    ) {
      this.flowModelFeedbackVisibility =
        settings['FlowModelFeedbackVisibility'].toLowerCase();
    }

    if (settings['IntercomPollDelay']) {
      const value = +settings['IntercomPollDelay'];
      if (Number.isFinite(value) && value > 0) {
        this.intercomConfig.pollDelay = value;
      } else {
        console.error(
          `Invalid IntercomPollDelay of ${value}. Using ${this.intercomConfig.pollDelay}`
        );
      }
    }

    if (
      settings['SessionTimeoutInSecond'] &&
      this.isSettingOverwritable('sessionTimeoutInSecond')
    ) {
      this.sessionTimeoutInSecond = +settings['SessionTimeoutInSecond'];
    }

    if (
      settings['StepTimeCostVisibility'] &&
      this.isSettingOverwritable('stepTimeCostVisibility')
    ) {
      this.stepTimeCostVisibility =
        settings['StepTimeCostVisibility'].toLowerCase();
    }

    if (this.isSettingOverwritable('iPaaSSupportedStepTypes')) {
      this.iPaaSSupportedStepTypes = settings['IPaaSSupportedStepTypes'] || '';
    }

    if (this.isSettingOverwritable('EnableIPaaSIntegration')) {
      this.enableIPaaSIntegration = this.isTrueString(
        settings['EnableIPaaSIntegration']
      );
    }

    if (this.isSettingOverwritable('enableDatabaseFeature')) {
      this.enableDatabaseFeature = this.isTrueString(
        settings['EnableDatabaseFeature']
      );
    }

    if (this.isSettingOverwritable('enableTenantSwitching')) {
      this.enableTenantSwitching = this.isTrueString(
        settings['EnableTenantSwitching']
      );
    }

    if (this.isSettingOverwritable('brandingHelpUri')) {
      this.brandingHelpUri = settings['Branding:HelpUri'];
    }

    if (this.isSettingOverwritable('showReportDatesInUtc')) {
      this.showReportDatesInUtc = this.isTrueString(
        settings['ShowReportDatesInUtc']
      );
    }

    if (
      settings['HideInviteUsers'] &&
      this.isSettingOverwritable('hideInviteUsers')
    ) {
      this.hideInviteUsers = this.isTrueString(settings['HideInviteUsers']);
    }

    if (settings['ModelerUrl'] && this.isSettingOverwritable('modelerUrl')) {
      this.modelerUrl = settings['ModelerUrl'];
    }

    if (
      settings['Flowingly.Core.ServiceCatalog.Communications'] &&
      this.isSettingOverwritable('signalrServerUrl')
    ) {
      this.signalrServerUrl =
        settings['Flowingly.Core.ServiceCatalog.Communications'] + '/signalr';
    }

    if (settings['IntercomAppId'] && this.isSettingOverwritable('intercomId')) {
      this.intercomId = settings['IntercomAppId'];
    }

    if (
      settings['auth0:UseUniversalLogin'] &&
      this.isSettingOverwritable('auth0UseUniversalLogin')
    ) {
      this.auth0UseUniversalLogin = this.isTrueString(
        settings['auth0:UseUniversalLogin']
      );
    }

    if (
      settings['FlowinglyEnvironment'] &&
      this.isSettingOverwritable('flowinglyEnvironment')
    ) {
      this.flowinglyEnvironment = settings['FlowinglyEnvironment'];
    }

    if (this.isSettingOverwritable('allowProcessMap')) {
      if (
        settings['AllowProcessMap'] &&
        settings['AllowProcessMap'].toString().toLowerCase() === 'false'
      ) {
        this.allowProcessMap = false;
      } else {
        this.allowProcessMap = true;
      }
    }

    if (
      settings['MaxSubCategories'] &&
      this.isSettingOverwritable('maxSubCategories')
    ) {
      this.maxSubCategories = parseInt(settings['MaxSubCategories']);
    }

    if (
      settings['EnableProcessMapsV2'] &&
      this.isSettingOverwritable('enableProcessMapsV2')
    ) {
      this.enableProcessMapsV2 = this.isTrueString(
        settings['EnableProcessMapsV2']
      );
    }

    const defaultHomePage = settings['DefaultHomePage']?.trim();
    if (defaultHomePage) {
      this.defaultHomePage = defaultHomePage;
    }

    if (settings['EnableExago'] && this.isSettingOverwritable('enableExago')) {
      this.enableExago = this.isTrueString(settings['EnableExago']);

      if (settings['ExagoDefaultDashboardConfig']) {
        try {
          const temp = JSON.parse(settings['ExagoDefaultDashboardConfig']);

          if (temp['folder']) {
            this.exagoConfig.defaultDashboard.folder = temp['folder'];
          }

          if (temp['name']) {
            this.exagoConfig.defaultDashboard.name = temp['name'];
          }
        } catch (e) {
          console.error(
            'Problem parsing business setting ExagoDefaultDashboardConfig'
          );
        }
      }
    }

    if (
      settings['DisableAdminTeamEdit'] &&
      this.isSettingOverwritable('disableAdminTeamEdit')
    ) {
      this.disableAdminTeamEdit = this.isTrueString(
        settings['DisableAdminTeamEdit']
      );
    }

    if (
      settings['DisableAdminUserEdit'] &&
      this.isSettingOverwritable('disableAdminUserEdit')
    ) {
      this.disableAdminUserEdit = this.isTrueString(
        settings['DisableAdminUserEdit']
      );
    }

    if (
      settings['DisableAdminCategoryEdit'] &&
      this.isSettingOverwritable('disableAdminCategoryEdit')
    ) {
      this.disableAdminCategoryEdit = this.isTrueString(
        settings['DisableAdminCategoryEdit']
      );
    }

    if (
      settings['DisableAdminDatabaseDelete'] &&
      this.isSettingOverwritable('disableAdminDatabaseDelete')
    ) {
      this.disableAdminDatabaseDelete = this.isTrueString(
        settings['DisableAdminDatabaseDelete']
      );
    }

    if (
      settings['EnableReportOwner'] &&
      this.isSettingOverwritable('enableReportOwner')
    ) {
      this.enableReportOwner = this.isTrueString(settings['EnableReportOwner']);
    }

    if (settings['DefaultDays'] && this.isSettingOverwritable('defaultDays')) {
      this.defaultDays = +settings['DefaultDays'];
    }

    if (
      settings['MaxReportReturnRows'] &&
      this.isSettingOverwritable('maxReportReturnRows')
    ) {
      this.maxReportReturnRows = +settings['MaxReportReturnRows'];
    }

    if (
      settings['MaximumWebhooksOnStep'] &&
      this.isSettingOverwritable('maximumWebhooksOnStep')
    ) {
      this.maximumWebhooksOnStep =
        parseInt(settings['MaximumWebhooksOnStep']) || 5;
    }

    if (
      settings['MaxNumberOfDaysForRecentlyUpdatedMaps'] &&
      this.isSettingOverwritable('maxNumberOfDaysForRecentlyUpdatedMaps')
    ) {
      this.maxNumberOfDaysForRecentlyUpdatedMaps = parseInt(
        settings['MaxNumberOfDaysForRecentlyUpdatedMaps']
      );
    }

    if (
      settings['EnablePublicProcessMaps'] &&
      this.isSettingOverwritable('EnablePublicProcessMaps')
    ) {
      this.enablePublicProcessMaps = this.isTrueString(
        settings['EnablePublicProcessMaps']
      );
    }

    if (
      settings['EnableFlowDelete'] &&
      this.isSettingOverwritable('EnableFlowDelete')
    ) {
      this.tryApplySetting('EnableFlowDelete', true);
    }

    this.tryApplySetting('EnableFormFieldReCaptcha', true);
    this.tryApplySetting('EnableModelerFlowModelTypeSelection', true);

    // TODO once switchover to new infrastructure is complete we can update
    // these settings instead of modifying them
    const legacyPath = '/Client/dist/';
    if (
      settings['RunnerClosedLogoUrl'] &&
      this.isSettingOverwritable('runnerClosedLogoUrl')
    ) {
      this.runnerClosedLogoUrl =
        settings['RunnerClosedLogoUrl'].indexOf(legacyPath) > -1
          ? settings['RunnerClosedLogoUrl'].split(legacyPath)[1]
          : settings['RunnerClosedLogoUrl'];
    }

    if (
      settings['RunnerOpenLogoUrl'] &&
      this.isSettingOverwritable('runnerOpenLogoUrl')
    ) {
      this.runnerOpenLogoUrl =
        settings['RunnerOpenLogoUrl'].indexOf(legacyPath) > -1
          ? settings['RunnerOpenLogoUrl'].split(legacyPath)[1]
          : settings['RunnerOpenLogoUrl'];
    }

    if (
      settings['GoogleTagManagerId'] &&
      this.isSettingOverwritable('googleTagManagerId')
    ) {
      this.googleTagManagerId = settings['GoogleTagManagerId'];
    }

    if (
      settings['FlowsInDateFilterDefaultRange'] &&
      this.isSettingOverwritable('FlowsInDateFilterDefaultRange')
    ) {
      this.flowsInDateFilterDefaultRange =
        settings['FlowsInDateFilterDefaultRange'];
    }

    if (
      settings['FlowsInDateFilterMaxRange'] &&
      this.isSettingOverwritable('FlowsInDateFilterMaxRange')
    ) {
      this.flowsInDateFilterMaxRange = settings['FlowsInDateFilterMaxRange'];
    }

    if (
      settings['Flowingly.Core.ServiceCatalog.Runner'] &&
      this.isSettingOverwritable('runnerRehomeUrl')
    ) {
      this.runnerRehomeUrl = settings['Flowingly.Core.ServiceCatalog.Runner'];
    }

    this.setReady();
  }
}

angular.module('flowingly.services').constant('APP_CONFIG', new APP_CONFIG());

export type APP_CONFIGType = InstanceType<typeof APP_CONFIG>;
