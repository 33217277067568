/**
 * @ngdoc service
 * @name tempModelerUrlService
 * @module flowingly.services
 *
 * @description A service responsible solely for opening the modeler in a new tab
 *
 * ## Notes
 * It is expected that this will be a temporary service
 */
import { Guid } from '@Shared.Angular/@types/guid';

/// i.e. we return response.data.DataModel so the consumer can work with the data directly

angular
  .module('flowingly.services')
  .factory('tempModelerUrlService', tempModelerUrlService);

tempModelerUrlService.$inject = [
  'APP_CONFIG',
  'guidService',
  'sessionService',
  'flowinglyConstants'
];

function tempModelerUrlService(
  APP_CONFIG,
  guidService,
  sessionService,
  flowinglyConstants
) {
  const service = {
    openModeler: openModeler,
    getModelerUrl: getModelerUrl,
    openLinkInNewTab: openLinkInNewTab
  };

  return service;

  /// Public API Methods /////////////////////////////////////////////////////////////////

  function openModeler(
    flowId: Guid,
    cloneModel = false,
    openFeedback = false,
    templateKey = null
  ) {
    const url = getModelerUrl(flowId, cloneModel, openFeedback, templateKey);
    openLinkInNewTab(url);
  }

  /// PRIVATE METHODS /////////////////////////////////////////////////////////////////

  function getModelerUrl(
    flowId: Guid,
    cloneModel = false,
    openFeedback = false,
    templateKey = null
  ) {
    const workflowId = flowId === undefined ? guidService.empty() : flowId;
    const token = sessionService.getToken();

    let modelerUrl = `${APP_CONFIG.modelerUrl}/modeler/#/workflow?workflowId=${workflowId}&clone=${cloneModel}&openFeedback=${openFeedback}`;
    if (templateKey) {
      modelerUrl = modelerUrl + `&templateKey=${templateKey}`;
    }
    if (
      APP_CONFIG.flowinglyEnvironment ===
      flowinglyConstants.flowinglyEnvironments.Local
    ) {
      return modelerUrl + `&token=${token}`;
    } else {
      return modelerUrl;
    }
  }

  //firefox & IE11 need a DOM click event to be fired!
  // otherwise simply a.click() would work with Chrome etc.
  function openLinkInNewTab(url) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    if (document.createEvent) {
      const ev = document.createEvent('MouseEvent');
      ev.initMouseEvent(
        'click',
        true /* bubble */,
        true /* cancelable */,
        window,
        null,
        0,
        0,
        0,
        0 /* coordinates */,
        false,
        false,
        false,
        false /* modifier keys */,
        0 /*left*/,
        null
      );
      a.dispatchEvent(ev);
    } else {
      a.click();
    }
  }
}

export type TempModelerUrlServiceType = ReturnType<
  typeof tempModelerUrlService
>;
